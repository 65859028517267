import axios from '../../axios/app';

const DonorsService = {

    getDonorsPagination: (numPage) => {
        return axios.get(`/rest/donors/pageable?page=${numPage}&size=10`)
    },
    getAllDonors: () => {
        return axios.get('/rest/donors/all');
    },
    getAllDonorsWithoutStatus: () => {
        return axios.get('/rest/donors/allWithoutStatus');
    },
    addDonors: (donor) =>
    {
        return axios.post(`/rest/donors`, donor)
    },
    editDonors: (donor) => {
        return axios.patch(`/rest/donors`, donor)
    },
    getDonorById: (id) => {
        return axios.get(`/rest/donors/${id}`);
    }


};

export default DonorsService;
