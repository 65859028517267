import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DeleteModal = props => {

    const [show, setShow] = useState(false);

    const handleDelete = () => {
        props.doDelete().then(() => {
            setShow(false);
        });
    }

    return (
        <Aux>
            <Button className={"btn btn-danger btn-sm " + props.btnClass}
                    onClick={() => setShow(true)}><FontAwesomeIcon icon={faTrashAlt} size="lg" style={{paddingRight:'4px'}} />Remove</Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"container"}>
                        {props.prompt}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>Cancel</Button>
                    <Button className="btn btn-danger" type="submit" onClick={handleDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Aux>
    );
}


export default DeleteModal;
