import React, {Component} from "react";
import {Link} from "react-router-dom";
import Background from "../../assets/images/abstract2-bg.jpg";
import AuthenticationService from "../../repository/service/AuthenticationService";
import {toast} from "react-toastify";

class ContactSystemAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            message: "",
            errors: {}
        }
    }

    handleEmailChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['email'] = errorMessage;
        else
            delete errors['email'];
        this.setState({errors: errors || {}});

        this.setState({email: e.target.value})
    }
    handleMessageChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['message'] = errorMessage;
        else
            delete errors['message'];
        this.setState({errors: errors || {}});

        this.setState({message: e.target.value})
    }


    validateProperty = (e) => {
        if (e.target.message === 'message') {
            if (e.target.value === '' || e.target.value === undefined) return 'Message is required!';
        }
        if (e.target.name === 'email') {
            if (e.target.value === '' || e.target.value === undefined) return 'Email is required!';
        }
    }

    validation = () => {
        const errors = {};
        if (this.state.email === '' || this.state.email === undefined)
            errors.email = 'Email is required!'

        if (this.state.message === '' || this.state.message === undefined)
            errors.message = 'Message is required!'
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        const errors = this.validation();
        this.setState({errors: errors || {}});
        if (errors)
            return;

        const form = new FormData();
        form.append("email", this.state.email);
        form.append("message", this.state.message);
        AuthenticationService.contactSysAdmin(form).then(() => {
            this.setState({
                email: "",
                message: ""
            })
            toast.success("Your message is successfully sent");
            this.props.history.push("/registerNewUser")
        }).catch(() => {
            toast.error("Your message failed to send")
        })
    }

    render() {
        return (
            <div className="kt-grid kt-grid--ver kt-grid--root kt-page kt-grid-page  ">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin"
                     id="kt_login">
                    <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div
                            className="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                            <div className="kt-login__wrapper">
                                <div className="kt-login__container">
                                    <div className="kt-login__body">
                                        <div className="kt-login__logo">
                                                                     <a href="!#">
                                                <img src={require('../../assets/images/MTSP1.png')}
                                                     className={"img-responsive mtsp-logo"} alt=""/>
                                            </a>
                                        </div>
                                        <div className="kt-login__signin container-fluid">
                                            <div className="kt-login__head">
                                                <h6 className="portalTitle">Ministry of Labor and Social Policy</h6>
                                                <br/>

                                            </div>

                                            <div className="kt-login__form">
                                                <br/><br/><br/>
                                                <h3 className="kt-login__title title justify-content-center d-flex">Contact</h3>
                                                <br/><br/>
                                                <form className="kt-form container-fluid"
                                                      onSubmit={(e) => this.onFormSubmit(e)}>


                                                    <label htmlFor='email'>Email:</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="email"
                                                               placeholder={" eg. jameswalter@gmail.com"}
                                                               onChange={(e) => this.handleEmailChange(e)}
                                                               name="email" autoComplete="off"/>
                                                        {this.state.errors.email &&
                                                        <div
                                                            className="alert alert-danger error-msg">{this.state.errors.email}</div>}
                                                    </div>
                                                    <br/>
                                                    <label htmlFor='message'>Message:</label>
                                                    <div className="form-group">
                                                            <textarea className="form-control"
                                                                      placeholder={" Write your message here"}
                                                                      style={{height: "200px"}}
                                                                      onChange={(e) => this.handleMessageChange(e)}
                                                                      name="message" autoComplete="off"/>
                                                        {this.state.errors.message &&
                                                        <div
                                                            className="alert alert-danger error-msg">{this.state.errors.message}</div>}
                                                    </div>

                                                    <div className="kt-login__actions">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <button type="submit" id="kt_login_signin_submit"
                                                                        className="btn btn-brand btn-pill btn-elevate">Submit
                                                                </button>
                                                            </div>
                                                            <div className="col-6">
                                                                <Link to={'/registerNewUser'}>
                                                                    <button type="back" id="kt_login_signin_submit"
                                                                            className="btn btn-brand btn-pill btn-elevate">Back
                                                                    </button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
                            style={{backgroundImage: "url(" + Background + ")"}}>
                            <div className="kt-login__section">
                                <div className="kt-login__block">
                                    <div className="kt-login__desc">
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default ContactSystemAdmin;