import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';

class ContactOneDonorModal extends Component{

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            entity: {
                customMessage: 'There have been changes in your project titled: '+ this.props.projectName +
                    '. These changes might be relevant to you in regards to budgeting and administration within the duration of ' +
                    'the project and its accomplishments. ' +
                    'Please refer to this email link: ' + this.props.linkToProject + ' to revise the project data.'
            
            }
        }
    }


    handleChange = (e) => {
        const target = e.target || e;
        let update = this.state.entity;
        update[target.name] = target.value;
        this.setState({entity: update});
    }

    show = () => {
        this.setState({
            show: true
        });
    }


    close = () => {
        this.setState({
            show: false,
        });
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit(this.state.entity).then(() => {
            this.close();
        });
    }




    render() {
        const Body = this.props.body;
        let waitingResponse = this.props.waitingResponse;
        let message = 'There have been changes in your project titled: '+ this.props.projectName +
            '. These changes might be relevant to you in regards to budgeting and administration within the duration of ' +
            'the project and its accomplishments. ' +
            'Please refer to this email link: ' + this.props.linkToProject + ' to revise the project data.';
        return (
            <Aux>
                <Button className={"btn " + this.props.btnClass}
                        onClick={this.show}>Contact donor</Button>
                <Modal show={this.state.show} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"container"}>
                            {waitingResponse ?<div className={"text-center"}> <img style={{width: "50%",height:"50%"}} alt=""
                                                                                   src={require('../../assets/images/loading.gif')}/> </div> : <Body entity={this.state.entity}
                                  message={message}
                                  handleChange={this.handleChange}
                                  onSubmit={this.handleSubmit}
                            />}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.close}>Close</Button>
                        <Button className="btn btn-primary" type="submit" onClick={this.handleSubmit}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            </Aux>
        )
    }
}

export default ContactOneDonorModal;