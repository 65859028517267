import axios from '../../axios/app';

const MainPrAreasService = {

    getMainPrAreasPagination: (numPage) => {
        return axios.get(`rest/mainPrAreas/pageable?page=${numPage}&size=10`)
    },
    getAllPrAreas: () => {
        return axios.get('rest/mainPrAreas/all');
    },
    addMainPrAreas: (mainAreas) =>
    {
        return axios.post(`rest/mainPrAreas`, mainAreas)
    },
    editMainPrAreas: (mainAreas) => {
        return axios.patch(`rest/mainPrAreas`, mainAreas)
    },
    getMainPrAreaById: (id) => {
        return axios.get(`/rest/mainPrAreas/${id}`);
    }

};

export default MainPrAreasService;
