import React, {Component} from 'react';
import ReactPaginate from 'react-paginate';
import SourceOfFinancingService from "../../repository/service/SourceOfFinancingService";
import CrudModal from '../Crud/CrudModal';
import EditSource from './EditSource';
import AddSource from './AddSource';
import {toast} from "react-toastify";


class SourceOfFinancingTable extends Component{

    constructor(props){
        super(props);
        this.state = {
            sourceOfFinancing : [],
            pageCount: 0
        }
    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData = (selectedPage = 0) => {
        SourceOfFinancingService.getSourceOfFinancingPagination(selectedPage).then((resp)=>{
            this.setState({
                sourceOfFinancing: resp.data.content,
                pageCount: resp.data.totalPages
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handlePageClick = (data) => {
        this.fetchData(data.selected);
    }

    addSourceOfFinancing = (sourceOfFinancing) =>{
        return SourceOfFinancingService.addSourceOfFinancing(sourceOfFinancing).then(resp =>{
            this.fetchData();
            toast.success("Successfully added source of financing.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() =>{
            toast.error("There is a problem adding source of financing.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }

    editSourceOfFinancing = (sourceOfFinancing) =>{
        return SourceOfFinancingService.editSourceOfFinancing(sourceOfFinancing).then(resp =>{
            this.fetchData();
            toast.success("Successfully edited source of financing.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() =>{
            toast.error("There is a problem editing source of financing.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }


    renderSourceOfFinancing = (main) =>{
        return (
            <tr key={main.id}>
                <td>{main.name}</td>

                <td>{main.status?"Active":"Inactive"}</td>
                <td>

                    <CrudModal title='Edit'
                               btnClass="btn-success"
                               entity={main}
                               modalTitle="Edit Source Of Financing"
                               onSubmit={this.editSourceOfFinancing}
                               body={EditSource}
                    />

                </td>
            </tr>
        )
    }



    render(){
        return(
            <div className={"container"}>
                <br/>

                <CrudModal title='Add'
                           entity={{}}
                           modalTitle="Add Source Of Financing"
                           onSubmit={this.addSourceOfFinancing}
                           body={AddSource}
                />
                <br/>
                <br/>
                <table className="table table-hover table-bordered">
                    <thead className="thead-dark ">
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.sourceOfFinancing.map(this.renderSourceOfFinancing)}
                    </tbody>
                </table>
                <div className="container-fluid">
                    <ReactPaginate
                        previousLabel={'prev'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        )
    }
}

export default SourceOfFinancingTable;
