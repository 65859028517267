import React, {Component} from 'react';
import ReactPaginate from 'react-paginate';
import MainPrAreasService from "../../repository/service/MainPrAreasService";
import CrudModal from '../Crud/CrudModal';
import EditMainPrAreas from './EditMainPrAreas';
import AddMainPrAreas from './AddMainPrAreas';
import {toast} from "react-toastify";


class MainPrAreasTable extends Component{

    constructor(props){
        super(props);
        this.state = {
            mainPrAreas : [],
            pageCount: 0
        }
    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData = (selectedPage = 0) => {
        MainPrAreasService.getMainPrAreasPagination(selectedPage).then((resp)=>{
            this.setState({
                mainPrAreas: resp.data.content,
                pageCount: resp.data.totalPages
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handlePageClick = (data) => {
        this.fetchData(data.selected);
    }

    addMainPrAreas = (mainAreas) =>{
        return MainPrAreasService.addMainPrAreas(mainAreas).then(resp =>{
            this.fetchData();
            toast.success("Successfully added main priority area.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() =>{
            toast.error("There is a problem adding main priority area.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }

    editMainPrAreas = (mainAreas) =>{
        return MainPrAreasService.editMainPrAreas(mainAreas).then(resp =>{
            this.fetchData();
            toast.success("Successfully edited main priority area.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() =>{
            toast.error("There is a problem editing main priority area.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }


    renderMainPrAreas = (main) =>{
        return (
            <tr key={main.id}>
                <td>{main.name}</td>

                <td>{main.status?"Active":"Inactive"}</td>
                <td>

                    <CrudModal title='Edit'
                               btnClass="btn-success"
                               entity={main}
                               modalTitle="Edit Main Areas"
                               onSubmit={this.editMainPrAreas}
                               body={EditMainPrAreas}
                    />

                </td>
            </tr>
        )
    }



    render(){
        return(
            <div className={"container"}>
                <br/>

                <CrudModal title='Add'
                           entity={{}}
                           modalTitle="Add Main Areas"
                           onSubmit={this.addMainPrAreas}
                           body={AddMainPrAreas}
                />
                <br/>
                <br/>
                <table className="table table-hover table-bordered">
                    <thead className="thead-dark ">
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.mainPrAreas.map(this.renderMainPrAreas)}
                    </tbody>
                </table>
            <div className="container-fluid">
                <ReactPaginate
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
            </div>
        )
    }
}

export default MainPrAreasTable;
