import React, {Component} from 'react';
import '../../assets/css/theme/login-6.css'
import Background from '../../assets/images/bg-4.jpg'
import {NavLink} from "react-router-dom";


class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ""
        }
    }

    render() {
        return (
            <div className="kt-grid kt-grid--ver kt-grid--root kt-page kt-grid-page">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin"
                     id="kt_login">
                    <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div
                            className="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                            <div className="kt-login__wrapper">
                                <div className="kt-login__container">
                                    <div className="kt-login__body">
                                        <div className="kt-login__logo">
                                            <a href="!#">
                                            </a>
                                        </div>
                                        <div className="kt-login__signin">
                                            <div className="kt-login__head">
                                                <h3 className="kt-login__title">Forgotten password</h3>
                                                <div className="kt-login__desc">Email
                                                </div>
                                            </div>
                                            <form className="kt-login__form" action="">
                                                <div className="kt-form">
                                                    <div className="form-group">
                                                        <input className="form-control" type="text"
                                                               placeholder="eg.jameswalter@gmail.com"
                                                               name="email" id="kt_email"
                                                               autoComplete="off"/>
                                                    </div>
                                                    <div className="kt-login__actions">
                                                        <button id="kt_login_forgot_submit"
                                                                className="btn btn-brand btn-pill btn-elevate"
                                                                type="button" onClick={this.sendRequest}>Submit
                                                        </button>
                                                        <NavLink id="kt_login_forgot_cancel"
                                                                 className="btn btn-outline-brand btn-pill"
                                                                 to={'/login'}>Close</NavLink>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
                            style={{backgroundImage: "url(" + Background + ")"}}>
                            <div className="kt-login__section">
                                <div className="kt-login__block">
                                    <div className="kt-login__desc">
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ResetPassword;