import React, {Component} from 'react';
import ReactPaginate from 'react-paginate';
import DonorsService from "../../repository/service/DonorsService";
import CrudModal from '../Crud/CrudModal';
import EditDonors from './EditDonors';
import AddDonors from './AddDonors';
import {toast} from "react-toastify";

class DonorsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            donors: [],
            pageCount: 0
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = (selectedPage = 0) => {
        DonorsService.getDonorsPagination(selectedPage).then((resp) => {

            this.setState({
                donors: resp.data.content,
                pageCount: resp.data.totalPages
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handlePageClick = (data) => {
        this.fetchData(data.selected);
    }

    addDonors = (donors) => {
        return DonorsService.addDonors(donors).then(resp => {
            this.fetchData();
            toast.success("Successfully added new donor.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() => {
            toast.error("There is a problem adding a new donor.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }

    editDonors = (donors) => {
        return DonorsService.editDonors(donors).then(resp => {
            this.fetchData();
            toast.success("Successfully edited donor.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() => {
            toast.error("There is a problem editing this donor.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }


    renderDonors = (donors) => {
        return (
            <tr key={donors.id}>
                <td>{donors.name}</td>

                <td>{donors.address}</td>
                <td>{donors.telephone}</td>
                <td>{donors.email}</td>

                <td>{donors.status ? "Active" : "Inactive"}</td>
                <td>

                    <CrudModal title='Edit'
                               btnClass="btn-success"
                               entity={donors}
                               modalTitle="Edit Donors"
                               onSubmit={this.editDonors}
                               body={EditDonors}
                    />

                </td>
            </tr>
        )
    }

    render() {
        return (
            <div className={"container"}>
                <br/>
                <div style={{display:"flex"}}>
                <CrudModal title='Add'
                           entity={{}}
                           modalTitle="Add Donors"
                           onSubmit={this.addDonors}
                           body={AddDonors}
                />
                </div>

                <br/>
                <table className="table table-hover table-bordered">
                    <thead className="thead-dark ">
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Telephone</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.donors.map(this.renderDonors)}
                    </tbody>
                </table>
                <div className="container-fluid">
                    <ReactPaginate
                        previousLabel={'prev'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        )
    }
}

export default DonorsTable;
