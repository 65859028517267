import React, { Component } from 'react';
import ProjectRegistryService from '../../repository/service/ProjectRegistryService';
import { Link } from 'react-router-dom';
import moment, { max } from "moment";
import UsersService from '../../repository/service/UserService';
import ReactPaginate from "react-paginate";
import { faEdit, faFile, faFileExcel, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CrudModalDesc from "../Crud/CrudModalDesc";
import './Css/ProjectRegistry.css'
import NumberFormat from "react-number-format";
import FileSaver from "file-saver"



class ProjectRegistryTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            projects: [],
            pageCount: 1,
            currentYear: 'Choose Year',
            endYear: 'Choose Year',
            oldYear: '',
            allYears: [],
            myImplOrg: {},         // if userDonor is logged in, fetch his donor
            keyword: ""
        }
    }

    componentDidMount() {
        this.fetchYears();
        if (this.state.currentYear !== "Choose Year" || this.state.endYear !=="Choose Year") {
            this.fetchData()
        }
        else {
            this.fetchDataWithoutYear();
        }
    }


    fetchData = (selectedPage = 0) => {
        if (this.state.currentYear != "Choose Year") {
            if (this.state.endYear != "Choose Year") {
                ProjectRegistryService.getProjectsPaginatedByBothYear(selectedPage, this.state.endYear,this.state.currentYear, this.state.keyword).then((resp) => {
                    this.setState({
                        projects: resp.data.content,
                        pageCount: resp.data.totalPages
                    })
                }).catch((err) => {
                    console.log(err);
                });
            }
            else{
            ProjectRegistryService.getProjectsPaginatedByYear(selectedPage, this.state.currentYear, this.state.keyword).then((resp) => {
                this.setState({
                    projects: resp.data.content,
                    pageCount: resp.data.totalPages
                })
            }).catch((err) => {
                console.log(err);
            });
            }
        }
        else if(this.state.endYear != "ChooseYear") {
            ProjectRegistryService.getProjectsPaginatedByEndYear(selectedPage, this.state.endYear, this.state.keyword).then((resp) => {
                this.setState({
                    projects: resp.data.content,
                    pageCount: resp.data.totalPages
                })
            }).catch((err) => {
                console.log(err);
            });
        }


        if (localStorage.getItem('role') === 'ROLE_IMPLEMENTATOR') {
            UsersService.getImplementatorFromLoggedUser().then((resp) => {
                this.setState({
                    myImplOrg: resp.data
                })
            })
        }
    }

    fetchDataWithoutYear = (selectedPage = 0) => {
        ProjectRegistryService.getProjectsPaginatedWithoutYear(selectedPage, this.state.keyword).then((resp) => {
            this.setState({
                projects: resp.data.content,
                pageCount: resp.data.totalPages
            })
        }).catch((err) => {
            console.log(err);
        });


        if (localStorage.getItem('role') === 'ROLE_IMPLEMENTATOR') {
            UsersService.getImplementatorFromLoggedUser().then((resp) => {
                this.setState({
                    myImplOrg: resp.data
                })
            })
        }
    }

    fetchYears = () => {
        ProjectRegistryService.getAllYears().then((resp) => {
            const minYear = Math.min(...resp.data)
            const maxYear = Math.max(...resp.data)
            var years= []
            for(var i=minYear;i<maxYear;i++){
                years.push(i)
            }
            this.setState({ allYears: years.reverse() });

        })
    }

    handleYearChange = (e) => {
        const year = e.target.value;
        if (year==="Choose Year" && this.state.endYear==="Choose Year") {
            this.setState({ currentYear: year }, () => {
                this.fetchDataWithoutYear(0, this.state.keyword);
            })
        }
        else {
            this.setState({ currentYear: year }, () => {
                this.fetchData(0, year, this.state.keyword);
            })
        }
    }

    handleEndYearChange = (e) => {
        const year = e.target.value;
        if (this.state.currentYear==="Choose Year" && year==="Choose Year") {
            this.setState({ endYear: year }, () => {
                this.fetchDataWithoutYear(0, this.state.keyword);
            })
        }
        else {
            this.setState({ endYear: year }, () => {
                this.fetchData(0, year, this.state.keyword);
            })
        }
    }
    handleSearchChange = (e) => {
        this.setState({ keyword: e.target.value }, () => {
            if (this.state.currentYear==="Choose Year" && this.state.endYear==="Choose Year") {
                this.fetchDataWithoutYear(0, this.state.keyword);
            }
            else {
                this.fetchData(0, this.state.currentYear, this.state.keyword);
            }
        })
    }


    renderYearsDropdown = () => {
        return (
            <select name='project_year' value={this.state.currentYear} className={"form-control custom-select w-50"}
                onChange={this.handleYearChange}>
                <option>Choose Year</option>
                {this.state.allYears.map(item => {
                    return (<option key={item} value={item}> {item}</option>)
                })}

            </select>
        )
    }

    renderEndYearsDropdown = () => {
        return (
            <select name='project_end_year' value={this.state.endYear} className={"form-control custom-select w-50"}
                onChange={this.handleEndYearChange}>
                <option>Choose Year</option>
                {this.state.allYears.map(item => {
                    return (<option key={item} value={item}> {item}</option>)
                })}

            </select>
        )
    }

    handlePageClick = (data) => {
        if (this.state.currentYear !== 'Choose Year') {
            if (this.state.oldYear != this.state.currentYear) {
                this.setState({ oldYear: this.state.currentYear })
                this.fetchData(0, this.state.currentYear, this.state.keyword);
            }
            else {
                this.fetchData(data.selected, this.state.currentYear, this.state.keyword);
            }
        }
        else {
            this.fetchDataWithoutYear(data.selected, this.state.keyword);
        }
    }

    exportReport = () => {
        if (this.state.currentYear === "Choose Year" && this.state.endYear === "Choose Year") {
            ProjectRegistryService.exportAll()
                .then(file => {
                    var blob = new Blob([file.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8' });
                    FileSaver.saveAs(blob, 'Izveshtaj.xlsx');
                })
        }
        else {
            ProjectRegistryService.exportYear(this.state.currentYear,this.state.endYear)
                .then(file => {
                    var blob = new Blob([file.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8' });
                    FileSaver.saveAs(blob, 'Izveshtaj.xlsx');
                })
        }
    
    }

    renderButtons = () => {
        // if admin is logged in
        return (
            <div className='container-fluid'>
                <div className='row mb-3 ml-1'>
                    {localStorage.getItem('role') === 'ROLE_ADMIN' || localStorage.getItem('role') === 'ROLE_IMPLEMENTATOR' ?
                        <div>
                            <Link to={{
                                pathname: '/projectRegistry/add'
                            }}>
                                <button className='btn btn-primary'><FontAwesomeIcon icon={faPlusCircle} size="lg"
                                    style={{ paddingRight: '4px' }} />Add
                                    new project
                                </button>
                            </Link>
                        </div> : null}
                    {localStorage.getItem('role') === 'ROLE_ADMIN' ?
                        <div className='d-flex flex-row bd-highlight mb-3'>
                            <div>
                                <Link to='/reports'>
                                    <button className='btn mb-3 ml-2'
                                        style={{ "backgroundColor": "#DC143C", "color": "white" }}><FontAwesomeIcon
                                            icon={faFile} size="lg" style={{ paddingRight: '4px' }} />Generate report
                                    </button>
                                </Link>
                            </div>
                            <div>
                                <button className='btn mb-3 ml-2' style={{ "backgroundColor": "#3CB371", "color": "white" }} onClick={this.exportReport}>
                                    {this.state.currentYear === 'Choose Year' && this.state.endYear === 'Choose Year' ?
                                        <div> <FontAwesomeIcon icon={faFileExcel} size="lg" style={{ paddingRight: '4px' }} />Export all</div>
                                        :
                                        <div> <FontAwesomeIcon icon={faFileExcel} size="lg" style={{ paddingRight: '4px' }} />Export</div>
                                    }
                                </button>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        )

    }

    renderProjects = (project) => {
        if (localStorage.getItem('role') === 'ROLE_IMPLEMENTATOR') {
            return this.renderProjectsForDonor(project);
        } else if (localStorage.getItem('role') === 'ROLE_USER') {
            return this.renderProjectsForUser(project);
        } else return this.renderProjectsForAdmin(project);

    }


    renderProjectsForAdmin = (project) => {
        return (
            <tr key={project.id}>
                <td>{project.mainPrAreas.name}</td>

                <td>{project.romaIntegrations}</td>
                <td><CrudModalDesc
                    btnClass="btn-info"
                    modalTitle="Impact Description"
                    message={project.impactDescription}
                /></td>
                <td>{project.sector.name}</td>
                <td>{project.donor.name}</td>

                <td>{project.otherPrArea}</td>
                <td>{project.title}</td>
                <td>
                    <CrudModalDesc
                        btnClass="btn-info"
                        modalTitle="Project Description"
                        message={project.descr}
                    />
                </td>
                <td>{project.totalMonths}</td>
                <td>{moment(project.startDate).local().format("DD-MM-YYYY")}</td>
                <td>{moment(project.endDate).local().format("DD-MM-YYYY")}</td>
                <td>{<NumberFormat value={project.totalAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                <td>{project.currency}</td>
                <td>{<NumberFormat value={project.currentYearAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                <td>{<NumberFormat value={project.nextYearAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                <td>{<NumberFormat value={project.furtherYearsAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                <td>{<NumberFormat value={project.nationalCofinanceAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                <td>
                    <CrudModalDesc
                        btnClass="btn-info"
                        modalTitle="Project Comment"
                        message={project.comment}
                    /></td>
                <td>{project.implOrg.orgName}</td>
                <td>{project.implementingPartner}</td>

                <td>
                    {project.projectDocumentationName ?
                        <a href={`/rest/projectRegistry/download/documentation/${project.id}`}>
                            Documentation
                        </a>
                        : null}
                </td>
                <td>
                    {project.projectReportName ?
                        <a href={`/rest/projectRegistry/download/report/${project.id}`}>
                            Report
                        </a>
                        : null}
                </td>

                <td>
                    <CrudModalDesc
                        btnClass="btn-info"
                        modalTitle="System Obstacles"
                        message={project.systemObstacles}
                    /></td>


                <td>
                    <div className='container-fluid'>
                        <div className='row'>
                            <Link to={'/projectRegistry/edit/' + project.id}>
                                <button className='btn btn-success mb-1'><FontAwesomeIcon icon={faEdit} size="lg"
                                    style={{ paddingRight: '4px' }} />
                                </button>
                            </Link>
                        </div>
                    </div>

                </td>
            </tr>

        )
    }

    renderProjectsForDonor = (project) => {
        return (
            <tr key={project.id}>
                <td>{project.mainPrAreas.name}</td>

                <td>{project.romaIntegrations}</td>
                <td><CrudModalDesc
                    btnClass="btn-info"
                    modalTitle="Impact Description"
                    message={project.impactDescription}
                /></td>

                <td>{project.sector.name}</td>
                <td>{project.donor.name}</td>

                <td>{project.otherPrArea}</td>
                <td>{project.title}</td>
                <td>
                    <CrudModalDesc
                        btnClass="btn-info"
                        modalTitle="Project Description"
                        message={project.descr}
                    />
                </td>
                <td>{project.totalMonths}</td>
                <td>{moment(project.startDate).local().format("DD-MM-YYYY")}</td>
                <td>{moment(project.endDate).local().format("DD-MM-YYYY")}</td>

                {/* total amount */}
                {
                    project.implOrg.id === this.state.myImplOrg.id ?            // if project is from donor
                        <td>{<NumberFormat value={project.totalAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                        : <td></td>                      // else - proj is from other donor
                }
                <td>{project.currency}</td>


                {/* currentYearAmount */}
                {
                    project.implOrg.id === this.state.myImplOrg.id ?            // if project is from donor
                        <td>{<NumberFormat value={project.currentYearAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                        : <td></td>                      // else - proj is from other donor
                }

                {/* nextYearAmount */}
                {
                    project.implOrg.id === this.state.myImplOrg.id ?            // if project is from donor
                        <td>{<NumberFormat value={project.nextYearAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                        : <td></td>                      // else - proj is from other donor
                }

                {/* furtherYearsAmount */}
                {
                    project.implOrg.id === this.state.myImplOrg.id ?            // if project is from donor
                        <td>{<NumberFormat value={project.furtherYearsAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                        : <td></td>                      // else - proj is from other donor
                }

                {/* nationalCofinanceAmount */}
                {
                    project.implOrg.id === this.state.myImplOrg.id ?            // if project is from donor
                        <td>{<NumberFormat value={project.nationalCofinanceAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                        : <td></td>                      // else - proj is from other donor
                }


                <td>
                    <CrudModalDesc
                        btnClass="btn-info"
                        modalTitle="Project Comment"
                        message={project.comment}
                    /></td>
                <td>{project.implOrg.orgName}</td>
                <td>{project.implementingPartner}</td>


                <td>
                    {project.projectDocumentationName ?
                        <a href={`/rest/projectRegistry/download/documentation/${project.id}`}>
                            Documentation
                        </a>
                        : null}
                </td>
                <td>
                    {project.projectReportName ?
                        <a href={`/rest/projectRegistry/download/report/${project.id}`}>
                            Report
                        </a>
                        : null}
                </td>
                <td>
                    <CrudModalDesc
                        btnClass="btn-info"
                        modalTitle="System Obstacles"
                        message={project.systemObstacles}
                    /></td>
                {/* Actions */}
                {
                    project.implOrg.id === this.state.myImplOrg.id ?            // if project is from donor
                        <td>
                            <Link to={'/projectRegistry/edit/' + project.id}>
                                <button className='btn btn-success'><FontAwesomeIcon icon={faEdit} size="lg"
                                    style={{ paddingRight: '4px' }} />
                                </button>
                            </Link>
                        </td> : <td></td>                   // else - proj is from other donor
                }

            </tr>
        )
    }

    renderProjectsForUser = (project) => {
        return (
            <tr key={project.id}>
                <td>{project.mainPrAreas.name}</td>

                <td>{project.romaIntegrations}</td>
                <td><CrudModalDesc
                    btnClass="btn-info"
                    modalTitle="Impact Description"
                    message={project.impactDescription}
                /></td>
                <td>{project.sector.name}</td>
                <td>{project.donor.name}</td>

                <td>{project.otherPrArea}</td>
                <td>{project.title}</td>
                <td>
                    <CrudModalDesc
                        btnClass="btn-info"
                        modalTitle="Project Description"
                        message={project.descr}
                    />
                </td>
                <td>{project.totalMonths}</td>
                <td>{moment(project.startDate).local().format("DD-MM-YYYY")}</td>
                <td>{moment(project.endDate).local().format("DD-MM-YYYY")}</td>
                <td>{<NumberFormat value={project.totalAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                <td>{project.currency}</td>
                <td>{<NumberFormat value={project.currentYearAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                <td>{<NumberFormat value={project.nextYearAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                <td>{<NumberFormat value={project.furtherYearsAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                <td>{<NumberFormat value={project.nationalCofinanceAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                <td>
                    <CrudModalDesc
                        btnClass="btn-info"
                        modalTitle="Project Comment"
                        message={project.comment}
                    /></td>
                <td>{project.implOrg.orgName}</td>
                <td>{project.implementingPartner}</td>

                <td>
                    {project.projectDocumentationName ?
                        <a href={`/rest/projectRegistry/download/documentation/${project.id}`}>
                            Documentation
                        </a>
                        : null}
                </td>
                <td>
                    {project.projectReportName ?
                        <a href={`/rest/projectRegistry/download/report/${project.id}`}>
                            Report
                        </a>
                        : null}
                </td>

                <td>
                    <CrudModalDesc
                        btnClass="btn-info"
                        modalTitle="System Obstacles"
                        message={project.systemObstacles}
                    /></td>

                <div></div>
            </tr>

        )
    }


    render() {
        return (
            <div className="container-fluid">
                <br />
                <div className="row mb-5">
                    <div className="col-lg-5 col-md-5 col-sm-5"><label htmlFor='project_year'>Choose start year:</label>
                        <br />
                        {this.renderYearsDropdown()}
                        <br />
                        <label htmlFor='project_year'>Choose end year:</label>
                        {this.renderEndYearsDropdown()}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                        <label>Search:</label>
                        <br />
                        <div className="form-group has-search">
                            <input type="text" className="form-control searchStyle" onChange={this.handleSearchChange}
                                placeholder="&#xF002; &nbsp; Example: project" />
                        </div>
                    </div>
                </div>

                <div className="row">{this.renderButtons()}</div>


                <br />
                <div style={{ overflowX: "auto" }}>
                    <table className="table table-hover table-bordered">
                        <thead className='thead-dark'>
                            <tr className="project-tr">
                                <th>Main priority area</th>
                                <th>Roma integration</th>
                                <th>Impact description</th>
                                <th>Sector</th>
                                <th>Donor</th>

                                <th>Other PR area</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Total months</th>
                                <th>Start date</th>
                                <th>End date</th>
                                <th>Total amount</th>
                                <th>Currency</th>
                                <th>Current year amount</th>
                                <th>Next year amount</th>
                                <th>Further years amount</th>
                                <th>National cofinance amount</th>
                                <th>Comment</th>
                                <th>Implementing organization</th>
                                <th>Implementing partner</th>
                                <th>Download documentation</th>
                                <th>Download report</th>
                                <th>System obstacles</th>

                                {
                                    localStorage.getItem('role') !== 'ROLE_USER' ?
                                        <th>Actions</th>
                                        :
                                        null
                                }

                            </tr>
                        </thead>
                        <tbody>
                            {this.state.projects.map(this.renderProjects)}
                        </tbody>
                    </table>
                </div>
                <div className="container-fluid mt-3">
                    <ReactPaginate
                        id="react-paginate"
                        previousLabel={'prev'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        )

    }
}

export default ProjectRegistryTable;