import React from 'react';


const EditImplementators = (props) => {

    return (
        <div className="container">
            <form>
                <div className='form-group'>
                    <label htmlFor='orgName' className='modal-label'><span style={{color:"red"}}>*</span>Organization Name: </label>
                    <br/>
                    <input type='text' id='orgName' name='orgName'
                           className='form-control roma-integration-input' value={props.entity.orgName} onChange={props.handleChange}
                    />
                    {props.error.name && <div className="alert alert-danger error-msg">{props.error.name}</div>}
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='orgAddress' className='modal-label'>Organization Address: </label>
                    <br/>
                    <input type='text' id='orgAddress' name='orgAddress'
                           className='form-control roma-integration-input' value={props.entity.orgAddress ? props.entity.orgAddress:""} onChange={props.handleChange}
                    />
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='orgTelephone' className='modal-label'>Organization Telephone: </label>
                    <br/>
                    <input type='text' id='orgTelephone' name='orgTelephone'
                           className='form-control roma-integration-input' value={props.entity.orgTelephone ? props.entity.orgTelephone:""} onChange={props.handleChange}
                    />
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='orgEmail' className='modal-label'>Organization Email: </label>
                    <br/>
                    <input type='email' id='orgEmail' name='orgEmail'
                           className='form-control roma-integration-input' value={props.entity.orgEmail ? props.entity.orgEmail:""} onChange={props.handleChange}
                    />
                    <br/>
                </div>


                <div className='form-group'>
                    <label htmlFor='status' className='modal-label'><span style={{color:"red"}}>*</span>Status: </label>
                    <br/>

                    <select onChange={props.handleChange} name='status' value={props.entity.status}
                            className='form-control roma-integration-input custom-select'>
                        <option value='true'>Active</option>
                        <option value='false'>Inactive</option>
                    </select>
                </div>
            </form>

        </div>
    )
}


export default EditImplementators;
