import React, { Component } from 'react';
import AuthenticationService from '../../repository/service/AuthenticationService';


class ChangePassword extends Component{
    
    constructor(props){
        super(props);

        this.state = {
            userEmail: localStorage.getItem('email'),
            oldPass: '',
            newPass: '',
            confirmPass: '',
            errors: {}
        }
    }

    handleOldPasswordChange = (e) => {
        e.preventDefault();

        this.setState({
            oldPass: e.target.value
        })
    }

    handleNewPasswordChange = (e) => {
        e.preventDefault();

        this.setState({
            newPass: e.target.value
        })
    }

    handleConfirmPasswordChange = (e) => {
        e.preventDefault();

        this.setState({
            confirmPass: e.target.value
        })
    }

    validation = () => {
        const errors = {};
        if(this.state.oldPass === '' || this.state.oldPass === undefined)
            errors.oldPass = 'The old password is required!'
        if(this.state.newPass === '' || this.state.newPass === undefined)
            errors.newPass = 'The new password is required!'
        if(this.state.confirmPass === '' || this.state.confirmPass === undefined)
            errors.confirmPass = 'The confirm password field is required!'
        if(this.state.newPass !== this.state.confirmPass)
            errors.confirmPass = 'Passwords do not match!'

        return Object.keys(errors).length === 0 ? null : errors;
    }


    onFormSubmit = (e) => {
        e.preventDefault();

        const errors = this.validation();
        this.setState({errors : errors || {}});
        if(errors) return;

        const changePassBody = {
            password: this.state.newPass,
            repeatPassword: this.state.confirmPass,
            oldPassword: this.state.oldPass,
            email: this.state.userEmail
        }


        AuthenticationService.changePassword(changePassBody).then((resp) =>{
            this.props.history.push('/projectRegistry');
        }).catch(()=>{
            this.renderWrongOldPasswordMessage();
        })

    }

    renderWrongOldPasswordMessage = () => {
        this.setState({
            errors: {oldPass: 'Wrong old password!'}
        })
    }

    render(){
        return(
            <div className='row container-fluid'>
                <div className='col'></div>
                <div className="container-fluid col m-5 bg-secondary">
                    <form onSubmit={this.onFormSubmit} className="container-fluid">

                        <br/>
                        <br/>
                        <br/>

                        <h4 className='text-white'>Change your password</h4>

                        <br/>
                        <div className='form-group'>
                            <label className='modal-label text-white'>Your old password:</label>
                            <input type='password' className='form-control change-pass-input' 
                                onChange={this.handleOldPasswordChange}/>
                            {this.state.errors.oldPass && 
                            <div className="alert alert-danger error-msg">{this.state.errors.oldPass}</div>}
                            <br/>



                            <label className='modal-label text-white'>Your new password:</label>
                            <input type='password' className='form-control change-pass-input' 
                                onChange={this.handleNewPasswordChange}/>
                            {this.state.errors.newPass && 
                            <div className="alert alert-danger error-msg">{this.state.errors.newPass}</div>}
                            <br/>


                            <label className='modal-label text-white'>Confirm new password:</label>
                            <input type='password' className='form-control change-pass-input' 
                                onChange={this.handleConfirmPasswordChange}/>
                            {this.state.errors.confirmPass && 
                            <div className="alert alert-danger error-msg">{this.state.errors.confirmPass}</div>}
                            <br/>
                        </div>
                        <button className='btn btn-primary project-registry-form-btn' type='submit'>Submit</button>
                        <button className='btn btn-danger project-registry-form-btn' type='reset'>Reset</button>
                        <button className='btn btn-dark project-registry-form-btn' onClick={()=>{
                            this.props.history.push('/projectRegistry')}}>Cancel</button>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </form>
                </div>
                <div className='col'></div>
            </div>
        )
    }
}

export default ChangePassword;