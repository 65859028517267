import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';
import {faEdit, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class CrudModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            entity: {},
            errors: {},
        }
    }

    componentDidMount() {
        this.setState({
            entity: {...this.props.entity}
        });
    }

    validation = () => {
        const errors = {};
        if (this.state.entity.name === '')
            errors.name = 'Name is required!'
        if (this.state.entity.surname === '')
            errors.surname = 'Surname is required!'
        if (this.state.entity.status === '')
            errors.status = 'Status is required!'
        if (this.state.entity.role === '')
            errors.role = 'Role is required!'
        if (this.state.entity.implOrgId === '')
            errors.name = 'Implementing Organization is required!'
        return Object.keys(errors).length === 0 ? null : errors;
    }

    validate = () => {
        return (this.state.entity.name === '' || this.state.entity.orgName === '' || this.state.errors.status === ''
            || this.state.entity.name === undefined || this.state.errors.status === undefined ||
            this.state.entity.surname === '' || this.state.errors.number === ''
            || this.state.entity.surname === undefined || this.state.errors.number === undefined)
    }

    validateProperty = (e) => {
        if (e.target.name === 'orgName') {
            if (e.target.value === '' || e.target.value === undefined) return 'Name is required!';
        }
        if (e.target.name === 'name') {
            if (e.target.value === '' || e.target.value === undefined) return 'Name is required!';
        }
        if (e.target.name === 'surname') {
            if (e.target.value === '' || e.target.value === undefined) return 'Surname is required!';
        }
        if (e.target.name === 'status') {
            if (e.target.value === '' || e.target.value === undefined) return 'Status is required!';
        }
        if (e.target.name === 'password') {
            if (e.target.value === '' || e.target.value === undefined) return 'Password is required!';
        }
        if (e.target.name === 'role') {
            if (e.target.value === '' || e.target.value === undefined) return 'Role is required!';
        }
        if (e.target.name === 'implOrgId') {
            if (e.target.value === '' || e.target.value === undefined) return 'Implementing Organization is required!';
        }
    }

    handleChange = (e) => {

        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage) errors[e.target.name] = errorMessage;
        else delete errors[e.target.name];

        const target = e.target || e;
        let update = this.state.entity;
        update[target.name] = target.value;
        this.setState({entity: update});
        this.setState({errors: errors || {}});
    }
    show = () => {
        this.setState({
            show: true
        });
    }


    close = () => {
        this.setState({
            show: false,
        });
    }


    handleSubmit = (e) => {
        e.preventDefault();
        const errors = this.validation();
        this.setState({errors: errors || {}});
        if (errors) return;

        this.props.onSubmit(this.state.entity).then(() => {
            this.close();
        });
    }

    render() {
        const Body = this.props.body;
        return (
            <Aux>
                <Button className={"btn " + this.props.btnClass}
                        onClick={this.show}>{this.props.title === "Add" ?
                    <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{paddingRight: '4px'}}/> :
                    <FontAwesomeIcon icon={faEdit} size="lg" style={{paddingRight: '4px'}}/>}{this.props.title}</Button>
                <Modal show={this.state.show} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"container"}>
                            <Body entity={this.state.entity}
                                  handleChange={this.handleChange}
                                  onSubmit={this.handleSubmit}
                                  error={this.state.errors}/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.close}>Close</Button>
                        <Button className="btn btn-primary" type="submit" onClick={this.handleSubmit}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            </Aux>
        )
    }
}

export default CrudModal;
