import axios from '../../axios/app';

const SectorsService = {

    getSectorsPaginated: (numPage) => {
        return axios.get(`/rest/sectors/pageable?page=${numPage}&size=10`)
    },

    getAllSectors: () => {
        return axios.get('/rest/sectors/all');
    },

    editSector: (newSector) => {
        return axios.patch('/rest/sectors', newSector);
    },

    addNewSector: (newSector) => {
        return axios.post('/rest/sectors', newSector);
    },

    getSectorById: (id) => {
        return axios.get(`/rest/sectors/${id}`);
    }

}

export default SectorsService;