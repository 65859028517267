import axios from '../../axios/app'

const AuthenticationService = {
    loginUser: (request) => {
        return axios.post('/rest/login', request);
    },

    changePassword: (changePassBody) => {
        return axios.post('/rest/users/change_password', null, {
            params: {
                password: changePassBody.password,
                oldPassword: changePassBody.oldPassword,
                repeatPassword: changePassBody.repeatPassword,
                email: changePassBody.email
            }
        });
    },
    
    createNewUserOrganization: (userDonorDTO) => {
        return axios.post('/rest/users/registerNewUserImplementator', userDonorDTO)
    },
    contactSysAdmin:(form) => {
        return axios.post("/rest/users/contactSystemAdmin",form)
    }

    };

export default AuthenticationService;