import React from 'react';

const ContactDonorModalBody = (props) =>{

    return(
        <div className='container'>
            <form>
                <div className='form-group'>
                    <label htmlFor='customMessage' className='modal-label'>Custom message: </label>
                    <br/>
                    <textarea id='customMessage' name='customMessage' className='form-control roma-integration-input'
                           defaultValue={props.message}
                           onChange={props.handleChange}/>
                    
                    <br/>
                </div>
            </form>
        </div>
    )
}

export default ContactDonorModalBody;