import React, {useEffect, useState} from 'react';
import ImplOrgService from "../../repository/service/ImplOrgService";


const EditUsers = (props) => {
    const [allOrgs, setAllOrgs] = useState([]);

    const getImplOrgs = () => {
        ImplOrgService.getAllActiveImplementators().then(res => {
            setAllOrgs(res.data)
        }).catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {
        getImplOrgs();
    }, []);


    return (
        <div className="container">
            <form>
                <div className='form-group'>
                    <label htmlFor='name' className='modal-label'>Name: </label>
                    <br/>
                    <input type='name' id='name' name='name'
                           className='form-control roma-integration-input'
                           value={props.entity.name}
                           onChange={props.handleChange}

                    />
                    {props.error.name && <div className="alert alert-danger error-msg">{props.error.name}</div>}
                    <br/>
                </div>
                <div className='form-group'>
                    <label htmlFor='surname' className='modal-label'>Surname: </label>
                    <br/>
                    <input type='surname' id='surname' name='surname'
                           className='form-control roma-integration-input'
                           value={props.entity.surname}
                           onChange={props.handleChange}

                    />
                    {props.error.surname && <div className="alert alert-danger error-msg">{props.error.surname}</div>}
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='telephone' className='modal-label'>Telephone: </label>
                    <br/>
                    <input type='text' id='telephone' name='telephone'
                           className='form-control roma-integration-input'
                           value={props.entity.telephone}
                           onChange={props.handleChange}

                    />
                    {props.error.number && <div className="alert alert-danger error-msg">{props.error.number}</div>}
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='email' className='modal-label'>Email: </label>
                    <br/>
                    <input type='email' id='email' name='email'
                           className='form-control roma-integration-input'
                           value={props.entity.email}
                           onChange={props.handleChange}

                    />
                    {props.error.email && <div className="alert alert-danger error-msg">{props.error.email}</div>}
                    <br/>
                </div>


                <div className='form-group'>
                    <label htmlFor='role' className='modal-label'>Role: </label>
                    <br/>

                    <select onChange={props.handleChange}
                            value={props.entity.role}
                            name='role'
                            className='form-control roma-integration-input custom-select'>
                        <option value="ROLE_USER">User</option>
                        <option value="ROLE_IMPLEMENTATOR">Implementing Organization</option>
                    </select>
                    {props.error.donor && <div className="alert alert-danger error-msg">{props.error.donor}</div>}
                    <br/>
                </div>

                {props.entity.role === "ROLE_IMPLEMENTATOR" ?
                    <div className='form-group'>
                        <label htmlFor='implOrgId' className='modal-label'>Implementing Organization: </label>
                        <br/>
                        <select onChange={props.handleChange} value={props.entity.implOrgId}
                                name='implOrgId' id="implOrgId"
                                className='form-control roma-integration-input custom-select'>
                            <option value="">-Select Implementing Organization-</option>
                            {allOrgs.map(implOrg => {
                                return (<option value={implOrg.id} key={implOrg.id}>{implOrg.orgName}</option>)
                            })}

                        </select>
                        {props.error.donor && <div className="alert alert-danger error-msg">{props.error.donor}</div>}
                        <br/>
                    </div> : null}

            </form>

        </div>
    )
}

export default EditUsers;