import React, { Component } from 'react';
import ProjectRegistryService from '../../repository/service/ProjectRegistryService';
import MainPrAreasService from '../../repository/service/MainPrAreasService';
import SectorsService from '../../repository/service/SectorsService';
import DonorsService from '../../repository/service/DonorsService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ContactOneDonorModal from '../ContactOneDonor/ContactOneDonorModal';
import ContactDonorModalBody from '../ContactOneDonor/ContactDonorModalBody';
import MapEntity from '../MapEntity/MapEntity';
import MapEntitySourceOfFinancing from "../MapEntitySourceOfFinancing/MapEntitySourceOfFinancing";
import ImplOrgService from "../../repository/service/ImplOrgService";


class EditProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentProject: {},
            mainPrAreas: [],
            sectors: [],
            donors: [],
            sourceOfFinancings: [],
            implOrgAll: [],

            startDate: new Date(),
            endDate: new Date(),
            sector: {},
            donor: {},
            implOrg: {},
            mainPrArea: {},
            romaIntegration: '',

            otherPrArea: '',
            title: '',
            descr: '',
            totalMonths: 0,
            implementingPartner: '',
            totalAmount: 0,
            currency: '',
            currentYearAmount: 0,
            nextYearAmount: 0,
            furtherYearsAmount: 0,
            nationalCofinanceAmount: 0,
            comment: '',
            impactDescription: '',

            projectDocumentationData: null,
            projectReportData: null,

            systemObstacles: '',
            interventions: [],

            errors: {},
            waitingResponse: false
        }
    }


    // INITAL DATA

    componentDidMount() {
        this.fetchCurrentProject(this.props.match.params.id);
        this.fetchData();
    }


    fetchCurrentProject = (projectId) => {
        ProjectRegistryService.getProjectById(projectId).then((resp) => {

            this.setState({
                currentProject: resp.data,
                otherPrArea: resp.data.otherPrArea,
                title: resp.data.title,
                descr: resp.data.descr,
                totalMonths: resp.data.totalMonths,
                implementingPartner: resp.data.implementingPartner,
                totalAmount: resp.data.totalAmount,
                currency: resp.data.currency,
                currentYearAmount: resp.data.currentYearAmount,
                nextYearAmount: resp.data.nextYearAmount,
                furtherYearsAmount: resp.data.furtherYearsAmount,
                nationalCofinanceAmount: resp.data.nationalCofinanceAmount,
                comment: resp.data.comment,

                startDate: resp.data.startDate,
                endDate: resp.data.endDate,

                implOrg: resp.data.implOrg,
                sector: resp.data.sector,
                donor: resp.data.donor,
                romaIntegration: resp.data.romaIntegrations,
                mainPrArea: resp.data.mainPrAreas,

                impactDescription: resp.data.impactDescription,
                systemObstacles: resp.data.systemObstacles,

            })

        })

    }

    fetchData = () => {

        MainPrAreasService.getAllPrAreas().then((resp) => {
            this.setState({
                mainPrAreas: resp.data
            })
        })

        SectorsService.getAllSectors().then((resp) => {
            this.setState({
                sectors: resp.data
            })
        })

        DonorsService.getAllDonors().then((resp) => {
            this.setState({
                donors: resp.data
            })
        })


        ImplOrgService.getAllActiveImplementators().then((resp) => {
            this.setState({ implOrgAll: resp.data })
        })
    }


    // INPUT HANDLING

    handleStartDateChange = date => {
        const errors = { ...this.state.errors };
        if (date == null) {
            const errorMessage = 'Start date is required'
            errors['startDate'] = errorMessage;
        } else {
            delete errors['startDate'];
        }
        this.setState({ errors: errors || {} });

        this.setState({
            startDate: date
        });
    };

    handleEndDateChange = date => {
        const errors = { ...this.state.errors };
        if (date == null) {
            const errorMessage = 'End date is required'
            errors['endDate'] = errorMessage;
        } else {
            delete errors['endDate'];
        }
        this.setState({ errors: errors || {} });

        this.setState({
            endDate: date
        });
    };

    handleOtherPrAreaChange = (e) => {
        e.preventDefault();

        this.setState({
            otherPrArea: e.target.value
        })

    }

    handleTitleChange = (e) => {
        e.preventDefault();
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['title'] = errorMessage;
        else
            delete errors['title'];

        this.setState({ title: e.target.value })

        this.setState({ errors: errors || {} });
    };

    handleDescriptionChange = (e) => {
        e.preventDefault();
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['description'] = errorMessage;
        else
            delete errors['description'];
        this.setState({ errors: errors || {} });


        this.setState({ descr: e.target.value })
    };

    handleTotalMonthsChange = (e) => {
        e.preventDefault();
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['totalMonths'] = errorMessage;
        else
            delete errors['totalMonths'];
        this.setState({ errors: errors || {} });

        this.setState({ totalMonths: e.target.value })
    };

    handleImplementingPartnerChange = (e) => {
        e.preventDefault();

        this.setState({ implementingPartner: e.target.value })
    };


    handleTotalAmountChange = (e) => {
        e.preventDefault();
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['totalAmount'] = errorMessage;
        else
            delete errors['totalAmount'];
        this.setState({ errors: errors || {} });

        this.setState({ totalAmount: e.target.value })
    };

    handleCurrencyChange = (e) => {
        e.preventDefault();
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['currency'] = errorMessage;
        else
            delete errors['currency'];
        this.setState({ errors: errors || {} });

        this.setState({ currency: e.target.value })
    };


    handleNextYearAmountChange = (e) => {
        e.preventDefault();
        this.setState({ nextYearAmount: e.target.value })
    };

    handleFurtherYearsAmountChange = (e) => {
        e.preventDefault();
        this.setState({ furtherYearsAmount: e.target.value })
    }

    handleCurrentYearAmountChange = (e) => {
        e.preventDefault();
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['currentYearAmount'] = errorMessage;
        else
            delete errors['currentYearAmount'];
        this.setState({ errors: errors || {} });

        this.setState({ currentYearAmount: e.target.value })
    };

    handleNationalCofinanceChange = (e) => {
        e.preventDefault();
        this.setState({ nationalCofinanceAmount: e.target.value })
    };

    handleCommentChange = (e) => {
        e.preventDefault();
        this.setState({ comment: e.target.value })
    };

    handleSystemObstaclesChange = (e) => {
        e.preventDefault();
        this.setState({ systemObstacles: e.target.value })
    };

    handleSectorChange = (e) => {

        SectorsService.getSectorById(e.target.value).then((resp) => {
            this.setState({
                sector: resp.data
            })
        })
    };

    handleRomaIntegrationChange = (e) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['romaIntegration'] = errorMessage;
        else
            delete errors['romaIntegration'];

        this.setState({ errors: errors || {} });

        this.setState({ romaIntegration: e.target.value });
    };

    handleDonorChange = (e) => {
        DonorsService.getDonorById(e.target.value).then((resp) => {
            this.setState({
                donor: resp.data
            })
        })
    };

    handleimplOrgChange = (e) => {
        e.preventDefault();
        ImplOrgService.getImplOrgId(e.target.value).then(resp => {
            this.setState({ implOrg: resp.data })
        })
    };

    handleMainPrAreaChange = (e) => {
        if (e.target.name !== "Other") {
            this.setState({ otherPrArea: '' })
        }

        MainPrAreasService.getMainPrAreaById(e.target.value).then((resp) => {

            this.setState({
                mainPrArea: resp.data
            })

        })
    };


    onProjDocumentationChange = (event) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(event); // should be application/pdf
        if (errorMessage)
            errors['projectDocumentation'] = errorMessage;
        else
            delete errors['projectDocumentation'];

        this.setState({ errors: errors || {} });

        this.setState({ projectDocumentationData: event.target.files[0] });
    };

    onProjReportChange = (event) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(event); // should be application/pdf
        if (errorMessage)
            errors['projectReport'] = errorMessage;
        else
            delete errors['projectReport'];

        this.setState({ errors: errors || {} });

        this.setState({ projectReportData: event.target.files[0] });
    };

    handleImpactDescriptionChange = (e) => {
        if (e !== undefined) {
            const errors = { ...this.state.errors };
            const errorMessage = this.validateProperty(e);
            if (errorMessage)
                errors['impactDescription'] = errorMessage;
            else
                delete errors['impactDescription'];
            this.setState({ errors: errors || {} });

            this.setState({ impactDescription: e.target.value });
        }
    }

    onSelectedInterventionChange = (e) => {
        this.setState({ interventions: e })
    }

    onSelectedSourceOfFinancingChange = (e) => {
        this.setState({ sourceOfFinancings: e })
    }


    // VALIDATION
    validation = () => {
        const errors = {};


        let startData = this.state.startDate
        let endData = this.state.endDate

        if (startData > endData)
            errors.endDate = 'End date must be after start date!'

        if (this.state.romaIntegration === 'DIRECT_IMPACT' || this.state.romaIntegration === 'INDIRECT_IMPACT') {
            if (this.state.impactDescription === '') {
                errors.impactDescription = 'Impact description is required when roma integration is direct or indirect impact!'
            }
        }

        if (this.state.title === '' || this.state.title === undefined)
            errors.title = 'Title is required!'
        if (this.state.descr === '' || this.state.descr === undefined)
            errors.description = 'Description is required!'
        if (this.state.totalMonths === '' || this.state.totalMonths === undefined)
            errors.totalMonths = 'Total months is required!'
        if (parseInt(this.state.totalMonths) <= 0) {
            errors.totalMonths = 'Total months must be > 0!'
        }
        if (this.state.totalAmount === '' || this.state.totalAmount === undefined)
            errors.totalAmount = 'Total amount is required!'
        if (parseInt(this.state.totalAmount) < 0)
            errors.totalAmount = 'Total amount must be >= 0!'

        if (this.state.currency === '' || this.state.currency === undefined)
            errors.currency = 'Currency is required!'

        if (this.state.currentYearAmount === '' || this.state.currentYearAmount === undefined)
            errors.currentYearAmount = 'Current year amount is required!'
        if (parseInt(this.state.currentYearAmount) < 0)
            errors.currentYearAmount = 'Current year amount must be >= 0!'

        if (this.state.projectDocumentationData != null) {
            if (this.state.projectDocumentationData.type !== 'application/pdf')
                errors.projectDocumentation = 'Project documentation must be in pdf format!'
        }
        if (this.state.projectReportData != null) {
            if (this.state.projectReportData.type !== 'application/pdf')
                errors.projectReport = 'Project report must be in pdf format!'
        }


        return Object.keys(errors).length === 0 ? null : errors;
    }


    validateProperty = (e) => {
        if (e.target.name === 'projectDocumentation') {
            if (e.target.files[0] !== undefined) {
                if (e.target.files[0].type !== 'application/pdf')
                    return 'Project documentation must be in pdf format!'
            }
        }
        if (e.target.name === 'projectReport') {
            if (e.target.files[0] !== undefined) {
                if (e.target.files[0].type !== 'application/pdf')
                    return 'Project report must be in pdf format!'
            }
        }
        if (e.target.name === 'romaIntegration') {
            if (e.target.value === 'Select roma integration' || e.target.value === undefined)
                return 'Roma Integration is required!';
        }
        if (e.target.name === 'impactDescription') {
            if (this.state.romaIntegration === 'DIRECT_IMPACT' || this.state.romaIntegration === 'INDIRECT_IMPACT') {
                if (e.target.value === '')
                    return 'Impact description is required when roma integration is direct or indirect impact!'
            }
        }

        if (e.target.name === 'title') {
            if (e.target.value === '' || e.target.value === undefined) return 'Title is required!';
        }
        if (e.target.name === 'description') {
            if (e.target.value === '' || e.target.value === undefined) return 'Description is required!';
        }
        if (e.target.name === 'totalMonths') {
            if (e.target.value === '' || e.target.value === undefined) return 'Total months is required!';
        }
        if (e.target.name === 'totalMonths') {
            if (parseInt(e.target.value) <= 0) return 'Total months must be > 0!';
        }
        if (e.target.name === 'totalAmount') {
            if (e.target.value === '' || e.target.value === undefined) return 'Total amount is required!';
        }
        if (e.target.name === 'totalAmount') {
            if (parseInt(e.target.value) < 0) return 'Total amount must be >= 0!';
        }
        if (e.target.name === 'currency') {
            if (e.target.value === '' || e.target.value === undefined) return 'Currency is required!';
        }
        if (e.target.name === 'currentYearAmount') {
            if (e.target.value === '' || e.target.value === undefined) return 'Current year amount is required!';
        }
        if (e.target.name === 'currentYearAmount') {
            if (parseInt(e.target.value) < 0) return 'Current year amount must be >= 0!';
        }
    }

    // RENDER FUNCTIONS


    renderMainPrAreas = (area) => {
        return <option key={area.id} value={area.id}>{area.name}</option>
    }

    renderSectors = (sector) => {
        return <option key={sector.id} value={sector.id}>{sector.name}</option>
    }
    renderDonors = (donor) => {
        return <option key={donor.id} value={donor.id}>{donor.name}</option>
    }
    renderImplOrgs = (implOrg) => {
        return <option key={implOrg.id} value={implOrg.id}>{implOrg.orgName}</option>
    }

    renderDonorsDropdown = () => {
        return (
            <div>
                <label htmlFor='donor' className=''>Donor:</label>
                <select name='donor' className='form-control' onChange={this.handleDonorChange}
                    defaultValue={this.state.donor.id}
                    value={this.state.donor.id}>
                    {this.state.donors.map(this.renderDonors)}
                </select>
            </div>
        )
    }
    renderImplOrgDropdowmn = () => {
        return (
            <div>
                <label htmlFor='implOrg' className=''>Implementing organization:</label>
                <select name='implOrg' className='form-control' onChange={this.handleimplOrgChange}
                    defaultValue={this.state.implOrg.id}
                    value={this.state.implOrg.id}>
                    {this.state.implOrgAll.map(this.renderImplOrgs)}
                </select>
            </div>
        )
    }
    renderProjDocumentationUpload = () => {
        return (
            <div>
                <label>Project documentation:</label>
                <br />
                <input type="file" name='projectDocumentation'
                    onChange={this.onProjDocumentationChange} />
                {this.state.errors.projectDocumentation &&
                    <div className="alert alert-danger error-msg">{this.state.errors.projectDocumentation}</div>}
                <br /><br />
            </div>
        )
    }
    renderProjReportUpload = () => {
        return (
            <div>
                <label>Project report:</label>
                <br />
                <input type="file" name='projectReport'
                    onChange={this.onProjReportChange} />
                {this.state.errors.projectReport &&
                    <div className="alert alert-danger error-msg">{this.state.errors.projectReport}</div>}
                <br /><br /><br />
            </div>
        )
    }
    renderImpactDescriptionField = () => {
        if (this.state.romaIntegration === 'Select roma integration' ||
            this.state.romaIntegration === 'NO_IMPACT' || this.state.romaIntegration === '') {
            return (
                <div className='form-group'>
                    <label htmlFor='impactDescription'>Impact description: </label>
                    <br />
                    <input type='text' id='impactDescription' name='impactDescription'
                        className='form-control'
                        onChange={this.handleImpactDescriptionChange}
                        defaultValue=''
                        value=''
                        readOnly />
                    {this.state.errors.impactDescription &&
                        <div className="alert alert-danger error-msg">{this.state.errors.impactDescription}</div>}
                    <br />
                </div>
            )
        } else {
            return (
                <div className='form-group'>
                    <label htmlFor='impactDescription' className=''>Impact description: </label>
                    <br />
                    <input type='text' id='impactDescription' name='impactDescription'
                        className='form-control'
                        onChange={this.handleImpactDescriptionChange}
                        defaultValue={this.state.impactDescription}
                        value={this.state.impactDescription}
                    />
                    {this.state.errors.impactDescription &&
                        <div className="alert alert-danger error-msg">{this.state.errors.impactDescription}</div>}
                    <br />
                </div>
            )
        }
    }


    contactOneDonor = (data) => {
        this.setState({ waitingResponse: true })
        return DonorsService.contactOneDonor(this.state.donor.id, data.customMessage).then(() => {
            this.setState({ waitingResponse: false })
            toast.success('Mail sent successfully')
        }).catch(() => {
            this.setState({ waitingResponse: false })
            toast.error('Failed to send mail')
        });
    }


    onFormSubmit = (formData) => {
        formData.preventDefault();
        const errors = this.validation();
        this.setState({ errors: errors || {} });
        if (errors)
            return;

        let impDsc;
        if (this.state.romaIntegration === 'NO_IMPACT') {
            impDsc = '';
        } else impDsc = this.state.impactDescription;


        const newProject = {
            id: this.state.currentProject.id,

            sector: this.state.sector,
            romaIntegration: this.state.romaIntegration,
            mainPrAreas: this.state.mainPrArea,
            donor: this.state.donor,

            otherPrArea: this.state.otherPrArea,
            title: this.state.title,
            descr: this.state.descr,


            startDate: this.state.startDate,
            endDate: this.state.endDate,
            totalMonths: this.state.totalMonths,


            currentYear: this.state.currentProject.currentYear,
            implementingPartner: this.state.implementingPartner,
            implOrg: this.state.implOrg,
            totalAmount: this.state.totalAmount,
            currency: this.state.currency,
            nextYearAmount: this.state.nextYearAmount,
            currentYearAmount: this.state.currentYearAmount,
            furtherYearsAmount: this.state.furtherYearsAmount,
            nationalCofinanceAmount: this.state.nationalCofinanceAmount,
            comment: this.state.comment,

            impactDescription: impDsc,

            systemObstacles: this.state.systemObstacles,

        }

        const frmData = new FormData();
        frmData.append("interventionsIds", this.state.interventions);
        frmData.append("sourceOfFinancingId", this.state.sourceOfFinancings);
        frmData.append('projectDocumentationData', this.state.projectDocumentationData)
        frmData.append('projectReportData', this.state.projectReportData)


        ProjectRegistryService.editProject(newProject, frmData).then((resp) => {
            this.props.history.push('/projectRegistry')
            toast.success("Successfully edited project.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            });
        }).catch(() => {
            toast.error("There is a problem editing this project.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }


    render() {

        return (
            <div className='container pt-3'>

                <form className='form' onSubmit={this.onFormSubmit}>


                    <label htmlFor='sector' className=''>Sector:</label>
                    <select name='sector' className='form-control' onChange={this.handleSectorChange}
                        defaultValue={this.state.sector.id}
                        value={this.state.sector.id}>
                        {this.state.sectors.map(this.renderSectors)}
                    </select>

                    <br />
                    <label htmlFor='romaIntegration' className=''>Roma integration:</label>
                    <select name='romaIntegration' className='form-control'
                        onChange={this.handleRomaIntegrationChange}
                        value={this.state.romaIntegration}
                    >
                        <option value='NO_IMPACT'>No impact</option>
                        <option value='INDIRECT_IMPACT'>Indirect impact</option>
                        <option value='DIRECT_IMPACT'>Direct impact</option>
                    </select>
                    {this.state.errors.romaIntegration &&
                        <div className="alert alert-danger error-msg">{this.state.errors.romaIntegration}</div>}
                    <br />
                    {this.renderImpactDescriptionField()}

                    <label htmlFor='main_pr_area' className=''>Main PR area:</label>
                    <select name='main_pr_area' className='form-control'
                        onChange={this.handleMainPrAreaChange}
                        defaultValue={this.state.mainPrArea.id}
                        value={this.state.mainPrArea.id}>
                        {this.state.mainPrAreas.map(this.renderMainPrAreas)}
                    </select>
                    <br />

                    <label htmlFor='otherPrArea' className=''>Other pr area:</label>
                    {this.state.mainPrArea.name === "Other" ?
                        <input type='text' id='otherPrArea' name='otherPrArea' className='form-control'
                            value={this.state.otherPrArea || ''}
                            onChange={this.handleOtherPrAreaChange} /> :
                        <input type='text' disabled={true} id='otherPrArea' name='otherPrArea'
                            value={this.state.otherPrArea} className='form-control' />}
                    <br />

                    <label htmlFor='title' className=''>Title:</label>
                    <input type='text' id='title' name='title' className='form-control'
                        value={this.state.title || ''}
                        onChange={this.handleTitleChange} />
                    {this.state.errors.title &&
                        <div className="alert alert-danger error-msg">{this.state.errors.title}</div>}
                    <br />
                    <label htmlFor='description' className=''>Description:</label>
                    <textarea id='description' name='description' className='form-control'
                        value={this.state.descr || ''}
                        onChange={this.handleDescriptionChange} />
                    {this.state.errors.description &&
                        <div className="alert alert-danger error-msg">{this.state.errors.description}</div>}

                    <br />

                    <label htmlFor='start_date' className=''>Start date:</label><br />
                    <DatePicker
                        selected={Date.parse(this.state.startDate)}
                        onChange={this.handleStartDateChange}
                        className="form-control"
                        name='start_date'
                        dateFormat='dd/MM/yyyy'
                    />
                    {this.state.errors.startDate &&
                        <div className="alert alert-danger error-msg">{this.state.errors.startDate}</div>}
                    <br />
                    <br />
                    <label htmlFor='end_date' className=''>End date:</label><br />
                    <DatePicker
                        selected={Date.parse(this.state.endDate)}
                        onChange={this.handleEndDateChange}
                        className="form-control"
                        name='end_date'
                        dateFormat='dd/MM/yyyy'
                    />
                    {this.state.errors.endDate &&
                        <div className="alert alert-danger error-msg">{this.state.errors.endDate}</div>}

                    <br /><br />

                    <label htmlFor='totalMonths' className=''>Total months:</label>
                    <input type='number' id='totalMonths' name='totalMonths' className='form-control'
                        value={this.state.totalMonths || ''}
                        onChange={this.handleTotalMonthsChange} />
                    {this.state.errors.totalMonths &&
                        <div className="alert alert-danger error-msg">{this.state.errors.totalMonths}</div>}
                    <br />

                    {this.renderDonorsDropdown()}
                    <br />

                    <label htmlFor='sourceOfFinancing' className=''>Source Of Financing:</label>

                    <MapEntitySourceOfFinancing
                        groupsUrl='rest/sourceOfFinancing/all'
                        onChange={this.onSelectedSourceOfFinancingChange}
                        entityUrl={"/rest/projectRegistry/selectedSourceOfFinancings/{id}"}
                        entityId={this.props.match.params.id}
                    />
                    <br />
                    {this.renderImplOrgDropdowmn()}

                    <br />

                    <label htmlFor='implementingPartner' className=''>Implementing partner:</label>
                    <input type='text' id='implementingPartner' name='implementingPartner' className='form-control'
                        value={this.state.implementingPartner || ''}
                        onChange={this.handleImplementingPartnerChange} />

                    <br />
                    <label htmlFor='totalAmount' className=''>Total amount:</label>
                    <input type='number' id='totalAmount' name='totalAmount' className='form-control'
                        value={this.state.totalAmount || ''}
                        onChange={this.handleTotalAmountChange} />
                    {this.state.errors.totalAmount &&
                        <div className="alert alert-danger error-msg">{this.state.errors.totalAmount}</div>}
                    <br />
                    <label htmlFor='currency' className=''>*Select Currency:</label>
                    <select name='currency' className='form-control custom-select' value={this.state.currency || ''}
                        onChange={this.handleCurrencyChange}>

                        <option></option>
                        <option>EUR</option>
                        <option>USD</option>
                        <option>MKD</option>
                    </select>
                    {this.state.errors.currency &&
                        <div className="alert alert-danger error-msg">{this.state.errors.currency}</div>}
                    <br />
                    <label htmlFor='currentYearAmount' className=''>Current year amount:</label>
                    <input type='number' id='currentYearAmount' name='currentYearAmount' className='form-control'
                        value={this.state.currentYearAmount || ''}
                        onChange={this.handleCurrentYearAmountChange} />
                    {this.state.errors.currentYearAmount &&
                        <div className="alert alert-danger error-msg">{this.state.errors.currentYearAmount}</div>}
                    <br />
                    <label htmlFor='nextYearAmount' className=''>Next year amount:</label>
                    <input type='number' id='nextYearAmount' name='nextYearAmount' className='form-control'
                        value={this.state.nextYearAmount || ''}
                        onChange={this.handleNextYearAmountChange} />
                    <br />
                    <label htmlFor='furtherYearsAmount' className=''>Further years amount:</label>
                    <input type='number' id='furtherYearsAmount' name='furtherYearsAmount' className='form-control'
                        value={this.state.furtherYearsAmount || ''}
                        onChange={this.handleFurtherYearsAmountChange} />
                    <br />
                    <label htmlFor='nationalCofinanceAmount' className=''>National cofinance amount:</label>
                    <input type='number' id='nationalCofinanceAmount' name='nationalCofinanceAmount'
                        className='form-control'
                        value={this.state.nationalCofinanceAmount || ''}
                        onChange={this.handleNationalCofinanceChange} />
                    <br />
                    <label htmlFor='comment' className=''>Comment:</label>
                    <textarea id='comment' name='comment' className='form-control'
                        value={this.state.comment || ''}
                        onChange={this.handleCommentChange} />
                    <br />


                    {this.renderProjDocumentationUpload()}
                    {this.renderProjReportUpload()}


                    <label>Interventions types:</label>

                    <MapEntity
                        groupsUrl='/rest/interventions/all'
                        onChange={this.onSelectedInterventionChange}
                        entityUrl={"/rest/projectRegistry/selectedInterventions/{id}"} // za edit gi zema site selektirani za za taa institucija
                        entityId={this.props.match.params.id}
                    />
                    <br />


                    <label htmlFor='systemObstacles' className=''>System Obstacles:</label>
                    <textarea id='systemObstacles' name='systemObstacles' className='form-control'
                        value={this.state.systemObstacles || ''}
                        onChange={this.handleSystemObstaclesChange} />
                    <br />

                    <button type='submit' className='btn btn-primary project-registry-form-btn'>Submit</button>

                    {localStorage.getItem('role') === "ROLE_ADMIN" ? <ContactOneDonorModal
                        title='Edit'
                        btnClass="btn btn-success mr-3"
                        entity={this.state.donor}
                        modalTitle="Contact donor"
                        onSubmit={this.contactOneDonor}
                        body={ContactDonorModalBody}
                        projectName={this.state.currentProject.title}
                        linkToProject={`/projectRegistry/edit/${this.state.currentProject.id}`}
                        waitingResponse={this.state.waitingResponse}
                    /> : null}


                    <button className='btn btn-dark' onClick={() => {
                        this.props.history.push('/projectRegistry')
                    }}>
                        Cancel
                    </button>
                    <br /><br />


                </form>
            </div>
        )


    }
}


export default EditProject;