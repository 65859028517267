import React from 'react';


const AddSource = (props) => {

    return (
        <div className="container">
            <form>
                <div className='form-group'>
                    <label htmlFor='name' className='modal-label'><span style={{color:"red"}}>*</span>Name: </label>
                    <br/>
                    <input type='text' id='name' name='name'
                           className='form-control roma-integration-input' onChange={props.handleChange}
                    />
                    {props.error.name && <div className="alert alert-danger error-msg">{props.error.name}</div>}
                    <br/>
                </div>


                <div className='form-group'>
                    <label htmlFor='status' className='modal-label'><span style={{color:"red"}}>*</span>Status: </label>
                    <br/>

                    <select onChange={props.handleChange} name='status'
                            className='form-control roma-integration-input custom-select'>
                        <option value="">Select status</option>
                        <option value='true'>Active</option>
                        <option value='false'>Inactive</option>
                    </select>
                    {props.error.status && <div className="alert alert-danger error-msg">{props.error.status}</div>}
                </div>
            </form>

        </div>
    )
}


export default AddSource;
