import React, {Component} from 'react';
import ReactPaginate from 'react-paginate';
import SectorsService from '../../repository/service/SectorsService';
import CrudModal from '../Crud/CrudModal';
import EditSector from './EditSector';
import AddNewSector from './AddNewSector';
import {toast} from "react-toastify";

class SectorsTable extends Component{

    constructor(props){
        super(props);

        this.state = {
            sectors : [],
            pageCount: 1
        }
        
    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData = (selectedPage = 0) => {
        SectorsService.getSectorsPaginated(selectedPage).then((resp)=>{

            this.setState({
                sectors: resp.data.content,
                pageCount: resp.data.totalPages
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handlePageClick = (data) => {
        this.fetchData(data.selected);
    }

    addNewSector = (newSector) =>{
        return SectorsService.addNewSector(newSector).then(resp =>{
            this.fetchData();
            toast.success("Successfully added new sector.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() =>{
            toast.error("There is a problem adding a new sector.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }

    editSector = (newSector) =>{
        return SectorsService.editSector(newSector).then(resp =>{
            this.fetchData();
            toast.success("Successfully edited sector.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() =>{
            toast.error("There is a problem editing this sector.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }



    renderSectors = (sector) =>{
        return (
            <tr key={sector.id}>
                <td>{sector.name}</td>
                {this.renderStatus(sector.status)}
                <td>
                    
                    <CrudModal title='Edit' 
                               modalTitle='Edit sector' 
                               entity={sector} 
                               onSubmit={this.editSector}
                               body={EditSector}
                               btnClass='btn-success btn-sm'
                               />
                </td>
            </tr>
        )
    }

    renderStatus = (statusBoolean) => {
        if(statusBoolean === true)
            return <td>Active</td>
        return <td>Inactive</td>
    }


    
    render(){
        return(
            <div className="container">

                <div className="container pt-4 pb-4 pl-0">
                    <CrudModal title='Add'
                            modalTitle='Add new sector'
                            entity={{}}
                            onSubmit={this.addNewSector}
                            body={AddNewSector} 
                            btnClass='btn-primary'/>
                </div>
                

                <table className="table table-hover table-bordered">
                    <thead className='thead-dark'>
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.sectors.map(this.renderSectors)}
                    </tbody>
                </table>
                <div className="container-fluid">
                <ReactPaginate
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
            </div>
        )
    }
}

export default SectorsTable;