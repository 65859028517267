import axios from '../../axios/app';

const InterventionTypesService = {

    getInterventionTypesPaginated: (numPage) => {
        return axios.get(`/rest/interventions/pageable?page=${numPage}&size=10`)
    },
    getAllInterventionTypes: () => {
        return axios.get('/rest/interventions/all');
    },
    // getAllInterventionTypesWithoutStatus: () => {
    //     return axios.get('/rest/interventions/allWithoutStatus');
    // },
    addInterventionTypes: (intervention) =>
    {
        return axios.post(`/rest/interventions`, intervention)
    },
    editInterventionTypes: (intervention) => {
        return axios.patch(`/rest/interventions`, intervention)
    }
}

export default InterventionTypesService;