import React, {useEffect, useState} from 'react';
import ImplOrgService from "../../repository/service/ImplOrgService";


const AddUsers = (props) => {
    const [role, setRole] = useState("");
    const [allOrgs, setAllOrgs] = useState([]);

    const getImplOrgs = () => {
        ImplOrgService.getAllActiveImplementators().then(res => {
            setAllOrgs(res.data)
        }).catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {
        getImplOrgs();
    }, []);


    const handleRoleChange = (e) => {
        setRole(e.target.value)
        props.handleChange(e)
    }

    return (
        <div className="container">
            <form>
                <div className='form-group'>
                    <label htmlFor='name' className='modal-label'><span style={{color:"red"}}>*</span>Name: </label>
                    <br/>
                    <input type='name' id='name' name='name'
                           className='form-control roma-integration-input' onChange={props.handleChange}
                    />
                    {props.error.name && <div className="alert alert-danger error-msg">{props.error.name}</div>}
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='surname' className='modal-label'><span style={{color:"red"}}>*</span>Surname: </label>
                    <br/>
                    <input type='surname' id='surname' name='surname'
                           className='form-control roma-integration-input' onChange={props.handleChange}
                    />
                    {props.error.surname && <div className="alert alert-danger error-msg">{props.error.surname}</div>}
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='telephone' className='modal-label'><span style={{color:"red"}}>*</span>Telephone: </label>
                    <br/>
                    <input type='text' id='telephone' name='telephone'
                           className='form-control roma-integration-input' onChange={props.handleChange}
                    />
                    {props.error.number && <div className="alert alert-danger error-msg">{props.error.number}</div>}
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='email' className='modal-label'><span style={{color:"red"}}>*</span>Email: </label>
                    <br/>
                    <input type='email' id='email' name='email'
                           className='form-control roma-integration-input' onChange={props.handleChange}
                    />
                    {props.error.email && <div className="alert alert-danger error-msg">{props.error.email}</div>}
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='password' className='modal-label'><span style={{color:"red"}}>*</span>Password: </label>
                    <br/>
                    <input type='password' id='password' name='password'
                           className='form-control roma-integration-input' onChange={props.handleChange}
                    />
                    {props.error.password && <div className="alert alert-danger error-msg">{props.error.password}</div>}
                    <br/>
                </div>


                <div className='form-group'>
                    <label htmlFor='role' className='modal-label'><span style={{color:"red"}}>*</span>Role: </label>
                    <br/>
                    <select onChange={handleRoleChange} name='role'
                            className='form-control roma-integration-input custom-select'>
                        <option value="">Select Role</option>
                        <option value="ROLE_ADMIN">Admin</option>
                        <option value="ROLE_USER">User</option>
                        <option value="ROLE_IMPLEMENTATOR">Implementing Organization</option>
                    </select>
                    {props.error.role && <div className="alert alert-danger error-msg">{props.error.role}</div>}
                    <br/>
                </div>

                {role === "ROLE_IMPLEMENTATOR" ?
                    <div className='form-group'>
                        <label htmlFor='implOrgId' className='modal-label'>Implementing Organization: </label>
                        <br/>

                        <select onChange={props.handleChange} name='implOrgId'
                                className='form-control roma-integration-input custom-select'>
                            <option value="">Select Implementing Organization</option>
                            {allOrgs.map(implOrg => {
                                return (<option value={implOrg.id} key={implOrg.id}>{implOrg.orgName}</option>)
                            })}
                        </select>
                        {props.error.donor && <div className="alert alert-danger error-msg">{props.error.donor}</div>}
                        <br/>
                    </div> : null}
            </form>

        </div>
    )
}

export default AddUsers;