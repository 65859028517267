import React, { Component } from 'react';
import ImplOrgService from "../../repository/service/ImplOrgService";
import CrudModal from "../Crud/CrudModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import AddImplementators from "./AddImplementators";
import EditImplementators from "./EditImplementators";
import { Modal, Button } from 'react-bootstrap';

class ImplOrg extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allOrgs: [],
            pageCount: 0,
            toShow: false,
            message: "",
            loader: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }
    fetchData = (selectedPage = 0) => {
        ImplOrgService.getImplementatorsPagination(selectedPage).then(res => {
            this.setState({
                allOrgs: res.data.content,
                pageCount: res.data.totalPages
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handlePageClick = (data) => {
        this.fetchData(data.selected);
    }

    renderOrgs = (implOrg) => {
        return (
            <tr key={implOrg.id}>
                <td>{implOrg.orgName}</td>

                <td>{implOrg.orgAddress ? implOrg.orgAddress : ''}</td>
                <td>{implOrg.orgTelephone ? implOrg.orgTelephone : ''}</td>
                <td>{implOrg.orgEmail ? implOrg.orgEmail : ''}</td>

                <td>{implOrg.status ? "Active" : "Inactive"}</td>
                <td>

                    <CrudModal title='Edit'
                        btnClass="btn-success"
                        entity={implOrg}
                        modalTitle="Edit Implementation Organization"
                        onSubmit={this.editImplementators}
                        body={EditImplementators}
                    />

                </td>
            </tr>
        )
    }

    addImplementators = (implOrg) => {
        return ImplOrgService.addImplOrg(implOrg).then(() => {
            this.fetchData();
            toast.success("Successfully added new Implementation Organization.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() => {
            toast.error("There is a problem adding a new Implementation Organization.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }
    editImplementators = (implOrg) => {
        return ImplOrgService.editImplOrg(implOrg).then(resp => {
            this.fetchData();
            toast.success("Successfully updated Implementation Organization.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() => {
            toast.error("There is a problem updating Implementation Organization.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }


    remindOrgs = () => {
        this.setState({ loader: true })
        if (this.state.message !== "") {
            ImplOrgService.remindImplementators(this.state.message).then(() => {
                toast.success("Successfully sent emails to all active implementing organizations.", {
                    position: "top-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    type: "success",

                })
                this.setState({
                    loader: false,
                    message: "",
                    toShow: false
                })
            }).catch(() => {
                toast.error("There is a problem sending emails to all active implementing organizations.", {
                    position: "top-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    type: "error",

                })
                this.setState({
                    loader: false,
                    message: "",
                    toShow: false
                })
            })
        }
        else {
            toast.error("Please enter a message to be sent.", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
            this.setState({
                loader: false,
                message: ""
            })
        }
    }

    toggleMsg = () => {
        this.setState({
            toShow: !this.state.toShow
        })
    }

    msgChange = (event) => {
        this.setState({
            message: event.target.value
        })
    }

    render() {
        return (
            <div className={"container"}>
                <br />
                <div style={{ display: "flex" }}>
                    <CrudModal title='Add'
                        entity={{}}
                        modalTitle="Add Implementation Organization"
                        onSubmit={this.addImplementators}
                        body={AddImplementators}
                    />

                    <button className='btn btn-warning btn-md ' style={{ marginLeft: "auto" }} onClick={(this.toggleMsg)}>
                        <FontAwesomeIcon icon={faEnvelope} size="lg" style={{ paddingRight: '4px' }} />
                        Remind Organizations</button>
                    <Modal show={this.state.toShow}>
                        <Modal.Header>
                            <Modal.Title>Contact Implementators</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.loader ? <div class="text-center text-primary">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div> :
                                <div className="container-fluid">
                                    <textarea style={{
                                        width: "100%",
                                        height: "20vh"
                                    }} defaultValue={this.state.message} onChange={this.msgChange}></textarea>
                                </div>}

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.toggleMsg}>Close</Button>
                            <Button className="btn btn-primary" type="submit" onClick={this.remindOrgs}>Send</Button>
                        </Modal.Footer>
                    </Modal>
                </div>


                <br />
                <table className="table table-hover table-bordered">
                    <thead className="thead-dark ">
                        <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Telephone</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.allOrgs.map(this.renderOrgs)}
                    </tbody>
                </table>
                <div className="container-fluid">
                    <ReactPaginate
                        previousLabel={'prev'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        )
    }
}
export default ImplOrg;