import React, {Component} from 'react';
import AuthenticationService from '../../repository/service/AuthenticationService';
import '../../assets/css/theme/login-6.css';
import '../Login/Login.css';
import Select from "react-select";
import Background from '../../assets/images/abstract2-bg.jpg'
import {Link} from "react-router-dom";
import ImplOrgService from "../../repository/service/ImplOrgService";

class RegisterNewUserOrganization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            surname: '',
            number: '',
            email: '',
            password: '',
            allImplementators: [],
            implementatorId: 0,

            showNewDonorFields: false,
            errors: {}
        }
    }

    componentDidMount() {
        this.fetchData();
    }


    fetchData = () => {
        ImplOrgService.getAllActiveImplementators().then((resp) => {

            let implOrgs = [];
            resp.data.map(item => {
                implOrgs.push({label: item.orgName, value: item.id})
            })
            this.setState({
                allImplementators: implOrgs,
                implementatorId: null,

            })
        })
    }


    handleNameChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['name'] = errorMessage;
        else
            delete errors['name'];
        this.setState({errors: errors || {}});

        this.setState({
            name: e.target.value
        });
    }

    handleSurnameChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['surname'] = errorMessage;
        else
            delete errors['surname'];
        this.setState({errors: errors || {}});

        this.setState({
            surname: e.target.value
        });
    }

    handleNumberChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['number'] = errorMessage;
        else
            delete errors['number'];
        this.setState({errors: errors || {}});

        this.setState({
            number: e.target.value
        });
    }

    handleEmailChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['email'] = errorMessage;
        else
            delete errors['email'];
        this.setState({errors: errors || {}});

        this.setState({
            email: e.target.value
        });
    }

    handlePasswordChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['password'] = errorMessage;
        else
            delete errors['password'];
        this.setState({errors: errors || {}});

        this.setState({
            password: e.target.value
        });

    }


    handleNewDonorNameChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['newDonorName'] = errorMessage;
        else
            delete errors['newDonorName'];
        this.setState({errors: errors || {}});


        this.setState({
            newDonorName: e.target.value
        })
    }
    handleNewDonorAddressChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['newDonorAddress'] = errorMessage;
        else
            delete errors['newDonorAddress'];
        this.setState({errors: errors || {}});

        this.setState({
            newDonorAddress: e.target.value
        })
    }
    handleNewDonorEmailChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['newDonorEmail'] = errorMessage;
        else
            delete errors['newDonorEmail'];
        this.setState({errors: errors || {}});

        this.setState({
            newDonorEmail: e.target.value
        })
    }
    handleNewDonorTelephoneChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['newDonorTelephone'] = errorMessage;
        else
            delete errors['newDonorTelephone'];
        this.setState({errors: errors || {}});

        this.setState({
            newDonorTelephone: e.target.value
        })
    }


    handleImplementatorChange = (e) => {
        debugger;
        if (e !== null) {
            this.setState({
                implementatorId: e.value
            })
        } else {
            this.setState({
                implementatorId: null
            })
        }
    }


    validateProperty = (e) => {
        if (e.target.name === 'name') {
            if (e.target.value === '' || e.target.value === undefined) return 'Name is required!';
        }
        if (e.target.name === 'surname') {
            if (e.target.value === '' || e.target.value === undefined) return 'Surname is required!';
        }
        if (e.target.name === 'telephone') {
            if (e.target.value === '' || e.target.value === undefined) return 'Telephone is required!';
        }
        if (e.target.name === 'email') {
            if (e.target.value === '' || e.target.value === undefined) return 'Email is required!';
        }
        if (e.target.name === 'password') {
            if (e.target.value === '' || e.target.value === undefined) return 'Password is required!';
        }
        if (e.target.name === 'implementator') {
            if (e.target.value === 'Select implementator' || e.target.value === undefined)
                return 'Implementation organization is required!';
        }
    }

    validation = () => {
        const errors = {};
        if (this.state.name === '' || this.state.name === undefined)
            errors.name = 'Name is required!'

        if (this.state.surname === '' || this.state.surname === undefined)
            errors.surname = 'Surname is required!'

        if (this.state.number === '' || this.state.number === undefined)
            errors.number = 'Telephone is required!'

        if (this.state.email === '' || this.state.email === undefined)
            errors.email = 'Email is required!'

        if (this.state.password === '' || this.state.password === undefined)
            errors.password = 'Password is required!'

        if (this.state.implementatorId === null || this.state.implementatorId === undefined || this.state.implementatorId === 'Select implementator')
            errors.implementator = 'Implementation organization is required!'


        return Object.keys(errors).length === 0 ? null : errors;
    }


    renderImplementators = (implementator) => {
        return <option key={implementator.id} value={implementator.id}>{implementator.orgName}</option>
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        const errors = this.validation();
        this.setState({errors: errors || {}});
        if (errors)
            return;

        let newUserDonorDTO;
        newUserDonorDTO = {
            user: {
                name: this.state.name,
                surname: this.state.surname,
                telephone: this.state.number,
                email: this.state.email,
                password: this.state.password,
                implOrg: null
            },
            implOrgId: this.state.implementatorId
        }

        AuthenticationService.createNewUserOrganization(newUserDonorDTO).then(
            this.props.history.push('/login')
        );
    }


    render() {

        return (

            <div className="kt-grid kt-grid--ver kt-grid--root kt-page kt-grid-page  ">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin"
                     id="kt_login">
                    <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div
                            className="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                            <div className="kt-login__wrapper">
                                <div className="kt-login__container">
                                    <div className="kt-login__body">
                                        <div className="kt-login__logo">
                                            <a href="!#">
                                                <img src={require('../../assets/images/MTSP1.png')}
                                                     className={"img-responsive mtsp-logo"} alt=""/>
                                            </a>
                                        </div>
                                        <div className="kt-login__signin container-fluid">
                                            <div className="kt-login__head">
                                                <h6 className="portalTitle">Ministry of Labor and Social Policy</h6>
                                                <br/>

                                            </div>

                                            <div className="kt-login__form">
                                                <br/><br/><br/>
                                                <h3 className="kt-login__title title justify-content-center d-flex">Register
                                                    new user</h3>
                                                <br/><br/>
                                                <form className="kt-form container-fluid"
                                                      onSubmit={(e) => this.onFormSubmit(e)}>

                                                    <label htmlFor='name'>Name:</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="name"
                                                               placeholder={" Name"}
                                                               onChange={(e) => this.handleNameChange(e)}
                                                               name="name" autoComplete="off"/>
                                                        {this.state.errors.name &&
                                                        <div
                                                            className="alert alert-danger error-msg">{this.state.errors.name}</div>}
                                                    </div>
                                                    <br/>

                                                    <label htmlFor='surname'>Surname:</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="surname"
                                                               placeholder={" Surname"}
                                                               onChange={(e) => this.handleSurnameChange(e)}
                                                               name="surname" autoComplete="off"/>
                                                        {this.state.errors.surname &&
                                                        <div
                                                            className="alert alert-danger error-msg">{this.state.errors.surname}</div>}
                                                    </div>
                                                    <br/>

                                                    <label htmlFor='telephone'>Telephone:</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text"
                                                               placeholder={" Telephone"}
                                                               onChange={(e) => this.handleNumberChange(e)}
                                                               name="telephone" autoComplete="off"/>
                                                        {this.state.errors.number &&
                                                        <div
                                                            className="alert alert-danger error-msg">{this.state.errors.number}</div>}
                                                    </div>
                                                    <br/>


                                                    <label htmlFor='email'>Email:</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="email"
                                                               placeholder={" eg. jameswalter@gmail.com"}
                                                               onChange={(e) => this.handleEmailChange(e)}
                                                               name="email" autoComplete="off"/>
                                                        {this.state.errors.email &&
                                                        <div
                                                            className="alert alert-danger error-msg">{this.state.errors.email}</div>}
                                                    </div>
                                                    <br/>

                                                    <label htmlFor='password'>Password:</label>
                                                    <div className="form-group">
                                                        <input type="password"
                                                               onChange={(e) => this.handlePasswordChange(e)}
                                                               className="form-control form-control-last"
                                                               placeholder={" Password"} name="password"/>
                                                        {this.state.errors.password &&
                                                        <div
                                                            className="alert alert-danger error-msg">{this.state.errors.password}</div>}
                                                    </div>
                                                    <br/>


                                                    <label htmlFor='implementator'>Implementing organization:</label>
                                                    <br/>
                                                    <Select
                                                        placeholder={"Search"}
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isDisabled={false}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isRtl={false}
                                                        isSearchable={true}
                                                        options={this.state.allImplementators}
                                                        onChange={this.handleImplementatorChange}

                                                    />

                                                    {this.state.errors.implementator &&
                                                    <div
                                                        className="alert alert-danger error-msg">{this.state.errors.implementator}</div>}
                                                    <br/><br/>

                                                    <div className="p-3 mb-2 bg-primary text-white">
                                                    <p>If your organization is not in the list of implementing
                                                        organizations, please contact us <Link
                                                            to="/contactsysadmin" class="text-warning">here</Link>.</p>
                                                    </div>
                                                    <br/><br/>



                                                    <div className="kt-login__actions">
                                                        <div className="row">
                                                            <div className="col-6">
                                                            <button type="submit" id="kt_login_signin_submit"
                                                                    className="btn btn-brand btn-pill btn-elevate">Submit
                                                            </button>
                                                            </div>
                                                            <div className="col-6">
                                                            <Link to={'/login'}>
                                                                <button type="back" id="kt_login_signin_submit"
                                                                        className="btn btn-brand btn-pill btn-elevate">Back
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
                            style={{backgroundImage: "url(" + Background + ")"}}>
                            <div className="kt-login__section">
                                <div className="kt-login__block">
                                    <div className="kt-login__desc">
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }

}

export default RegisterNewUserOrganization;