import axios from '../../axios/app';

const UserService = {


    getUsersPaginated: (numPage) => {
        return axios.get(`/rest/users/pageable?page=${numPage}&size=10`)
    },
    addUser: (newUser) => {
        return axios.post('/rest/users/adminCreate/newUser', newUser);
    },
    getAllUsers: () => {
        return axios.get('/rest/users/all');
    },
    getImplementatorFromLoggedUser: () => {
        return axios.get('/rest/users/getUser');
    },
    editUserByAdmin: (editUser) => {
        return axios.patch(`/rest/users/adminCreate/editUser`, editUser)
    },
    editProfile: (user) => {
        return axios.patch(`/rest/users/editProfile`, user)
    },

    deleteUserById: (id) => {
        return axios.delete(`/rest/users/delete/${id}`)
    },
    resetPassword: (email) => {
        return axios.post(`/rest/users/resetPassword?email=${email}`)
    }
};

export default UserService;