import React from 'react';


const EditDonors = (props) => {

    return(
        <div className="container">
            <form>
                <div className='form-group'>
                    <label htmlFor='name' className='modal-label'>Name: </label>
                    <br/>
                    <input type='text' id='name' name='name' className='form-control roma-integration-input'
                           value={props.entity.name}
                           onChange={props.handleChange}/>
                    {props.error.name && <div className="alert alert-danger error-msg">{props.error.name}</div> }
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='address' className='modal-label'>Address: </label>
                    <br/>
                    <input type='text' id='address' name='address'
                            value={props.entity.address}
                           className='form-control roma-integration-input' onChange={props.handleChange}
                    />
                    {props.error.address && <div className="alert alert-danger error-msg">{props.error.address}</div> }
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='telephone' className='modal-label'>Telephone: </label>
                    <br/>
                    <input type='text' id='telephone' name='telephone'
                            value={props.entity.telephone}
                           className='form-control roma-integration-input' onChange={props.handleChange}
                    />
                    {props.error.telephone && <div className="alert alert-danger error-msg">{props.error.telephone}</div> }
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='email' className='modal-label'>Email: </label>
                    <br/>
                    <input type='email' id='email' name='email'
                            value={props.entity.email}
                           className='form-control roma-integration-input' onChange={props.handleChange}
                    />
                    {props.error.email && <div className="alert alert-danger error-msg">{props.error.email}</div> }
                    <br/>
                </div>

                <div className='form-group'>
                    <label htmlFor='status' className='modal-label'>Status: </label>
                    <br/>
                    <select onChange={props.handleChange} name='status'
                            className="form-control roma-integration-input custom-select"
                            value={props.entity.status}>
                        <option value='true'>Active</option>
                        <option value='false'>Inactive</option>
                    </select>
                    
                </div>
            </form>

        </div>
    )
}



export default EditDonors;
