import React from 'react';


const EditInterventionType = (props) =>{

    return(
        <div className="container">
            <form>
                <div className='form-group'>
                    <label htmlFor='name' className='modal-label'>Name: </label>
                    <br/>
                    <input type='text' id='name' name='name' className='form-control roma-integration-input'
                            value={props.entity.name} 
                            onChange={props.handleChange}/>
                    {props.error.name && <div className="alert alert-danger error-msg">{props.error.name}</div>}
                    <br/>
                </div>
                
                <div className='form-group'>
                    <label htmlFor='status' className='modal-label'>Status: </label>
                    <br/>
                    <select onChange={props.handleChange} name='status' value={props.entity.status}
                    className='form-control roma-integration-input custom-select'>
                        <option value='true'>Active</option>
                        <option value='false'>Inactive</option>
                    </select>
                </div>
            </form>
            
        </div>
    )
}

export default EditInterventionType;